<script>
import api from '@/command/api'
import TemplateHeaderTable from '@/components/TemplateHeaderTable'
import moment from 'moment'
import { getAction, importAction } from '@/command/netTool'
import { giveTypeList } from '@/utils/textFile'
import apiTool from '@/command/apiTool'
import DrawerForm from '@/components/DrawerForm'

export default {
  name: 'couponsReceive',
  data() {
    return {
      ...api.command.getState(),
      dataObj: {
        giveNum: 0,
        receivedRate: 0,
        receivedTotal: 0,
        usedRate: 0,
        usedTotal: 0,
        weekReceivedTotal: 0,
        weekUsedTotal: 0
      }
    }
  },
  mounted() {
    this.getInit()
  },
  methods: {
    getInit() {
      const { id, turntableId } = this.$route.query
      if (id || turntableId) {
        api.command.getList.call(this, {
          url: '/base/farmCouponRule/queryReceivedUserList',
          current: 1,
          paramsValue: {
            id: id ? id : '',
            turntableId: turntableId ? turntableId : ''
          }
        })
        getAction(
          `/base/farmCouponRule/queryReceiveDetail?id=${id ? id : ''}&turntableId=${turntableId ? turntableId : ''}`
        ).then(result => {
          let dataArr = Object.keys(result.data)
          let entriesArr = Object.values(result.data)
          dataArr.forEach((e, i) => {
            this.dataObj[e] = entriesArr[i] ? entriesArr[i] : 0
          })
          this.$forceUpdate()
        })
      }
    },
    renderTop() {
      const data = [
        {
          name: '领取量',
          value: `${this.dataObj.receivedTotal}/${this.dataObj.giveNum}（${this.dataObj.receivedRate}）`
        },
        {
          name: '使用量',
          value: `${this.dataObj.usedTotal}/${this.dataObj.receivedTotal}（${this.dataObj.usedRate}）`
        },
        {
          name: '本周领取',
          value: `${this.dataObj.weekReceivedTotal}`
        },
        {
          name: '本周使用',
          value: `${this.dataObj.weekUsedTotal}`
        }
      ]
      return (
        <div class="home-top">
          {data.map(e => {
            return (
              <div class="home-top-item" onClick={() => e.onClick()}>
                <div>{e.value}</div>
                <div>{e.name}</div>
              </div>
            )
          })}
        </div>
      )
    },
    getHeader() {
      return [
        {
          name: '名称',
          key: 'name',
          type: 'input'
        },
        {
          name: '联系方式',
          key: 'telephone',
          type: 'input'
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          type: 'lt-100',
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'name',
          title: '领取人',
          type: 'lt-100'
        },
        {
          dataIndex: 'telephone',
          title: '联系方式',
          type: 'lt-100'
        },
        {
          dataIndex: 'receivedMode',
          title: '领取方式',
          type: 'lt-100',
          filterMultiple: false,
          filters: giveTypeList,
          customRender: function(text, records) {
            return (giveTypeList.find(e => e.value == text) || { name: '' }).name
          },
          onExport: (text, records) => {
            return (giveTypeList.find(e => e.value == text) || { name: '' }).name
          }
        },
        {
          dataIndex: 'status',
          title: '使用情况',
          width: 100,
          type: 'badge',
          align: 'left',
          filters: [
            {
              text: '已过期',
              value: '2'
            },
            {
              text: '已使用',
              value: '1'
            },
            {
              text: '未使用',
              value: '0'
            }
          ],
          onExport: records => {
            return ['未使用', '已使用', '已过期'][records]
          },
          filterMultiple: false,
          customRender: text => {
            let name = ''
            switch (text) {
              case '0':
                name = '未使用'
                break
              case '1':
                name = '已使用'
                break
              case '2':
                name = '已过期'
                break
            }
            return name
          }
        },
        {
          dataIndex: 'createTime',
          title: '领取时间',
          type: 'lt-100',
          sorter: (a, b) => moment(a.createTime).unix() - moment(b.createTime).unix()
        },
        {
          dataIndex: 'usedTime',
          title: '使用时间',
          type: 'lt-100',
          sorter: (a, b) => moment(a.usedTime).unix() - moment(b.usedTime).unix()
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          width: '8%',
          typeData: ({ records }) => {
            return [
              {
                display: true,
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () => {
                  api.command.del.call(this, {
                    url: '/farmUserCoupon/del',
                    params: {
                      id: records.id
                    }
                  })
                }
              }
            ]
          }
        }
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => {
            this.handlePopup()
          }
        }
      ]
    },
    getDrawerForm(source, formData) {
      const form = [
        {
          form: [
            {
              name: '手机号',
              type: 'input',
              key: 'phone',
              rules: [
                {
                  required: true,
                  type: 'string'
                }
              ]
            }
          ]
        }
      ]
      return form
    },
    handlePopup(dataSource = {}) {
      let that = this
      apiTool.showDrawer({
        title: '优惠券发放',
        width: '600px',
        view: DrawerForm,
        viewProps: {
          data: formData => that.getDrawerForm(dataSource, formData),
          form: dataSource
        },
        success: ({ data, setHidden }) => {
          let lxfs = /^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/
          if (!lxfs.test(data.phone)) {
            this.$message.warning('请填写或输入正确手机号！')
            return false
          }
          api.command.create
            .call(this, {
              url: '/farmUserCoupon/addUserCoupon',
              params: {
                ...data,
                couponRuleId: that.$route.query.id
              }
            })
            .then(setHidden)
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '取消',
              onClick: close
            },
            {
              name: '保存',
              type: 'primary',
              onClick: submit
            }
          ]
        }
      })
    },
    getRightButton() {
      return [
        {
          name: '导入Excel',
          onClick: () => {
            importAction('/api/farmUserCoupon/importData?couponRuleId=' + this.$route.query.id)
              .then(() => {
                this.$message.success('导入成功')
                this.getInit()
              })
              .catch(err => {
                this.$message.error('导入失败：' + err.msg)
              })
          }
        }
      ]
    }
  },
  render() {
    return (
      <div>
        {this.renderTop()}
        <TemplateHeaderTable
          headerData={this.getHeader()}
          tableColumns={this.getColumns()}
          records={this.records}
          button={this.getButton()}
          rightButton={this.getRightButton()}
        />
      </div>
    )
  }
}
</script>

<style lang="less" scoped>
.product-order-item {
  display: flex;
  align-items: center;

  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}
.home-top {
  display: flex;
  align-items: center;
  height: 101px;
  width: 100%;
  background: white;
  margin: 10px 0;
  .home-top-item {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    font-family: 'PingFang SC Normal', 'PingFang SC';
    font-weight: 500;
    font-style: normal;
    font-size: 20px;
    color: #515a6e;
    &:not(:last-child)::after {
      position: absolute;
      right: 0px;
      top: 0px;
      bottom: 0px;
      width: 1px;
      background: rgb(229, 231, 233);
      content: '';
    }
  }
}
/deep/.home-top-item {
  div {
    &:nth-of-type(2) {
      font-size: 14px;
      color: #515a6e;
    }
  }
}
</style>
